// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import AudioLibrary from "../../blocks/AudioLibrary/src/AudioLibrary";
import Mentionstagging from "../../blocks/Mentionstagging/src/Mentionstagging";
import ProjecttaskTracking2 from "../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import UserGroups from "../../blocks/UserGroups/src/UserGroups";
import ChatBackuprestore from "../../blocks/ChatBackuprestore/src/ChatBackuprestore";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Payments from "../../blocks/Payments/src/Payments";
import Videos from "../../blocks/videos/src/Videos";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import RealtimeUpdates from "../../blocks/RealtimeUpdates/src/RealtimeUpdates";
import Wishlist2 from "../../blocks/Wishlist2/src/Wishlist2";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import DashboardGuests from "../../blocks/DashboardGuests/src/DashboardGuests";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import BulletinBoard2 from "../../blocks/BulletinBoard2/src/BulletinBoard2";
import PrivateChat from "../../blocks/PrivateChat/src/PrivateChat";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import TimeTrackingBilling from "../../blocks/TimeTrackingBilling/src/TimeTrackingBilling";
import CvresumeCandidateManagement2 from "../../blocks/CvresumeCandidateManagement2/src/CvresumeCandidateManagement2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import TaskGroups from "../../blocks/TaskGroups/src/TaskGroups";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import TimesheetManagement from "../../blocks/TimesheetManagement/src/TimesheetManagement";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
AudioLibrary:{
 component:AudioLibrary,
path:"/AudioLibrary"},
Mentionstagging:{
 component:Mentionstagging,
path:"/Mentionstagging"},
ProjecttaskTracking2:{
 component:ProjecttaskTracking2,
path:"/ProjecttaskTracking2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
UserGroups:{
 component:UserGroups,
path:"/UserGroups"},
ChatBackuprestore:{
 component:ChatBackuprestore,
path:"/ChatBackuprestore"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Payments:{
 component:Payments,
path:"/Payments"},
Videos:{
 component:Videos,
path:"/Videos"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
RealtimeUpdates:{
 component:RealtimeUpdates,
path:"/RealtimeUpdates"},
Wishlist2:{
 component:Wishlist2,
path:"/Wishlist2"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
DashboardGuests:{
 component:DashboardGuests,
path:"/DashboardGuests"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
BulletinBoard2:{
 component:BulletinBoard2,
path:"/BulletinBoard2"},
PrivateChat:{
 component:PrivateChat,
path:"/PrivateChat"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
TimeTrackingBilling:{
 component:TimeTrackingBilling,
path:"/TimeTrackingBilling"},
CvresumeCandidateManagement2:{
 component:CvresumeCandidateManagement2,
path:"/CvresumeCandidateManagement2"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
TaskGroups:{
 component:TaskGroups,
path:"/TaskGroups"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
TimesheetManagement:{
 component:TimesheetManagement,
path:"/TimesheetManagement"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
component:Videos,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
